const routes = [
  {
    path: "/templates",
    name: "TemplatesListPage",
    meta: { listPage: "templates", listPageTitleKey: "templates" },
    component: () => import("@/views/app/pages/templates/list")
  },
  {
    name : "TemplatesNewPage",
    path: "/templates/new",
    component: () => import("@/views/app/pages/templates/new")
  },
  {
    name : "TemplatesNewPageWithConnectTo",
    path: "/templates/:connect_to/new",
    component: () => import("@/views/app/pages/templates/new")
  },
  {
    name : "TemplatesUpdatePage",
    path: "/templates/:id",
    component: () => import("@/views/app/pages/templates/update")
  }
];

export default routes;
