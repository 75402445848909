// ************ Custom Field Groups >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const routes = [
  {
    path: "/dashboards/",
    name: "DashboardsListPage",
    meta: { listPage: "dashboards", listPageTitleKey: "dashboards" },
    component: () => import("@/views/app/pages/dashboards/list")
  },
  {
    path: "/dashboards/new",
    component: () => import("@/views/app/pages/dashboards/new")
  },
  {
    path: "/dashboards/layout/:id",
    component: () => import("@/views/app/pages/dashboards/layouts")
  },
  {
    path: "/dashboards/:id",
    component: () => import("@/views/app/pages/dashboards/update")
  }
];
export default routes;
